<template>
  <ul :id="'children-'+parent">
    <li v-for="node in nodes" :id="'node-'+node.hashid"  @click.stop.prevent="openDetails(node)" >
        <b-card class="relative text-left mb-0 item organisation-objective" v-if="node.type=='Organisation'" >
          <div class="mb-0 w-100">
            <div class="goal-header text-center d-flex align-items-center justify-content-between">
              <h5 class="mb-0 obje-name font-weight-bolder mr-2" v-b-tooltip.hover.v-default :title="node.title">{{node.title}}</h5>
              <div class="circle_percent" :percent="node.progress">
                <div class="circle_inner">
                  <div class="round_per" ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="obj-period d-flex align-ites-center">
            <div class="quarter-data">
              <h6 class="mb-0 text-left">Period</h6>
              <p class="value text-left mb-0">{{node.period}}</p>
            </div>
          </div>
          <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon" v-if="node.children && node.children.length" @click.stop="listClicked(node);">
            <feather-icon icon="PlusIcon" size="20" class="before-expand" />
            <feather-icon icon="MinusIcon" size="20" class="after-expand" />
          </b-button>
        </b-card>
        <b-card class="relative text-left mb-0 item team-objective" v-if="node.type=='Team'" >
          <div class="mb-0 w-100">
            <b-badge variant="info" class="badge team-badge"> Team </b-badge>
            <div class="goal-header text-center d-flex align-items-center justify-content-between" >
              <h5 class="mb-0 obje-name font-weight-bolder mr-2" v-b-tooltip.hover.v-default :title="node.title">{{node.title}}</h5>
              <div class="circle_percent" :percent="node.progress">
                <div class="circle_inner">
                  <div class="round_per" ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center objective-objtype">
            <div class="obj-name d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Team</h6>
                <p class="value text-left mb-0" v-b-tooltip.hover.v-default :title="node.team_name">{{node.team_name}}</p>
              </div>
            </div>
            <div class="obj-period d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Period</h6>
                <p class="value text-left mb-0">{{node.period}}</p>
              </div>
            </div>
          </div>
          <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon" v-if="node.children && node.children.length" @click.stop="listClicked(node);">
            <feather-icon icon="PlusIcon" size="20" class="before-expand" />
            <feather-icon icon="MinusIcon" size="20" class="after-expand" />
          </b-button>
        </b-card>
        <b-card class="relative text-left mb-0 item indivdual-objective" v-if="node.type=='Individual'">
          <div class="mb-0 w-100">
            <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
            <div class="goal-header text-center d-flex align-items-center justify-content-between">
              <h5 class="mb-0 obje-name font-weight-bolder mr-2" v-b-tooltip.hover.v-default :title="node.title">{{node.title}}</h5>
              <div class="circle_percent" :percent="node.progress">
                <div class="circle_inner">
                  <div class="round_per" ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center objective-objtype">
            <div class="obj-name d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Name</h6>
                <p class="value text-left mb-0" v-b-tooltip.hover.v-default :title="node.user.name">{{node.user.name}}</p>
              </div>
            </div>
            <div class="obj-period d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Period</h6>
                <p class="value text-left mb-0">{{node.period}}</p>
              </div>
            </div>
          </div>
          <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon" v-if="node.children && node.children.length" @click.stop="listClicked(node);">
            <feather-icon icon="PlusIcon" size="20" class="before-expand" />
            <feather-icon icon="MinusIcon" size="20" class="after-expand" />
          </b-button>
        </b-card>
        <nodes v-if="node.children && node.children.length" :nodes="node.children" :parent="node.hashid" :ref="'children-'+node.hashid"></nodes>
        <!-- <div>
        <ul class="team-objective" style="display: flex">
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="20">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">Zircly</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q6</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
            <div>
              <ul class="third-node" style="display: block">
                <li>
                  <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="20">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center objective-objtype">
                      <div class="obj-name d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Name</h6>
                          <p class="value text-left mb-0">Vignesvaran B</p>
                        </div>
                      </div>
                      <div class="obj-period d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Period</h6>
                          <p class="value text-left mb-0">Q6</p>
                        </div>
                      </div>
                    </div>
                    <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
                  </b-card>
                </li>
                <li>
                  <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="20">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center objective-objtype">
                      <div class="obj-name d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Name</h6>
                          <p class="value text-left mb-0">Vignesvaran B</p>
                        </div>
                      </div>
                      <div class="obj-period d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Period</h6>
                          <p class="value text-left mb-0">Q6</p>
                        </div>
                      </div>
                    </div>
                    <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
                  </b-card>
                </li>
                <li>
                  <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="20">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center objective-objtype">
                      <div class="obj-name d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Name</h6>
                          <p class="value text-left mb-0">Vignesvaran B</p>
                        </div>
                      </div>
                      <div class="obj-period d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Period</h6>
                          <p class="value text-left mb-0">Q6</p>
                        </div>
                      </div>
                    </div>
                    <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
                  </b-card>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="40">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">Klizer</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q1</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="10">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">Integrator</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q6</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="5">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">PIM</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q1</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="50">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">QA Touch</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q6</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
        </ul>
        </div> -->
      
    </li>
    <!-- <li>
      <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="50">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="obj-period d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Period</h6>
                        <p class="value text-left mb-0">Q6</p>
                      </div>
                    </div>
        <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
      </b-card>
    </li>
    <li>
      <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="50">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="obj-period d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Period</h6>
                        <p class="value text-left mb-0">Q6</p>
                      </div>
                    </div>
        <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
      </b-card>
    </li> -->
    
    <!-- OKR Sidebar Detailed View -->
    <b-modal :id="'tree-objective-'+parent" hide-footer v-if="selectedNodes && selectedNodes.length && selectedNodes[0]">
      <template #modal-title #modal-header="{ close }" >
        <h5 class="modal-title" v-if="selectedNodes && selectedNodes.length == 1 && selectedNodes[0] && selectedNodes[0].type == 'Individual'"><span>{{selectedNodes[0].user.name}}</span>’s {{selectedNodes[0].quarter}}, {{selectedNodes[0].year}} {{ selectedNodes[0].type }} OKR</h5>
        <h5 class="modal-title" v-if="selectedNodes && selectedNodes.length == 1 && selectedNodes[0] && selectedNodes[0].type == 'Team'"><span>{{selectedNodes[0].team_name}}</span> {{selectedNodes[0].quarter}}, {{selectedNodes[0].year}} {{ selectedNodes[0].type }} OKR</h5>
        <h5 class="modal-title" v-if="selectedNodes && selectedNodes.length == 1 && selectedNodes[0] && selectedNodes[0].type == 'Organisation'">{{selectedNodes[0].quarter}}, {{selectedNodes[0].year}} {{ selectedNodes[0].type }} OKR</h5>
        <h5 class="modal-title" v-if="selectedNodes && selectedNodes.length > 1 ">Search Results : {{getSearchQuery()}}</h5>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20"
          @click="$bvModal.hide('tree-objective-'+parent)" />
      </template>
      <!-- Fixed Footer Related Okr Content -->
      <ul class="m-0 p-1 tree" v-if="selectedNodes && selectedNodes.length && selectedNodes[0]">
        <li class="list-unstyled mb-75" v-for="objective in selectedNodes">
          <div class="okr-detailview position-relative">
            <div class="progress-state">
              <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
            </div>
            <b-row class="d-flex align-items-center justify-content-between w-100 m-0 after-expand" v-b-toggle="'KeyResultsView-' + objective.hashid">
              <b-col sm="10" md="10" class="pl-0 d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                  <path d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z" fill="#667085" />
                </svg>
                <h5 class="main-header mb-0 w-100 pl-50">{{objective.title}}</h5>
              </b-col>
              <b-col sm="2" md="2" class="okr-status d-flex align-items-center justify-content-md-end pr-0 py-25">
                <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                  <div class="circle_inner">
                    <div class="round_per"></div>
                  </div>
                  <div class="circle_inbox"><span class="percent_text">{{Number(objective.overall_status.percentage)}}%</span>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-collapse :id="'KeyResultsView-' + objective.hashid" class="w-100 border-top mt-50">
                <ul class="sub-tree pl-2 pt-50">
                  <li class="list-unstyled border-0 w-100"  v-for="(key_result,k) in objective.key_results">
                    <div class="bordered-liness ">
                      <b-row class="border-bottom mx-0 pt-50 d-flex align-items-center border-0 pb-75">
                          <b-col class="align-items-center pl-0" sm="8" md="8">
                              <h5>{{key_result.result_description}}</h5>
                        </b-col>
                        <b-col sm="4" md="4" class="justify-content-md-end d-flex pr-0 text-md-right">
                          <div class="input-value">
                            <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state && key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                            <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                            <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress + " / " + key_result.goal + "%" }}</p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </li>
                </ul>
              </b-collapse>
          </div>
        </li>
        <!-- End Update-->
      </ul>
    </b-modal>
  </ul>
  
</template>

<script>
  import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Nodes from "./nodes.vue";
import axios from "@axios";
import $ from "jquery";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name:"nodes",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    vSelect,
    Nodes,
    ToastificationContent
  },
  props: {
    nodes: {
      type: Array,
      required: false,
    },
    parent: {
      type: String,
      required: true,
    },
    selectedObjective:{
    }
  },
  directives: {
    Ripple,
  },
  created() {
    if(this.nodes && this.nodes.length){
      for (let index = 0; index < this.nodes.length; index++) {
        const node = this.nodes[index];
        // node.children = this.getSubNodes(node);
        // alert(node.children);
        this.nodes[index] = node;
        this.getSubNodes(node,index);
        console.log(this.nodes[index]);
        this.$nextTick(() => {
            this.updateProgress();
            // const elements = this.$el.querySelectorAll(".nodes");
            // elements.forEach((element) => {
            //   element.addEventListener("click", this.handleDocumentClick);
            // });
          });
      }
    }
  },
 mounted () {    
    var nodeId = '#children-'+this.parent;
      $(nodeId).hide();
      $(nodeId+'.active').hide(); 
  },
  data() {
    return {
      objective:{},
      selectedNodes:[],
      searchType : {text:'',type:''},
    }
  },
  watch: {
    objective(val) {
      this.$emit('update:selectedObjective',val);
    },
  },
  methods: {
    getSearchQuery(){
      if(this.searchType.type && this.searchType.type == 'employee_id'){
        if(this.searchType.text.code && this.searchType.text.code){
          return this.searchType.text.label;
        }
      }else{
        return this.searchType.text;
      }
    },
    getStatusVariant(status){
      if(status == 'Upcoming'){
        return 'light-info';
      }else if(status == 'Not Started Yet'){
        return 'light-notyet';
      }else if(status == 'In Progress'){
        return 'light-warning';
      }else if(status == 'Completed'){
        return 'light-primary';
      }else if(status == 'Cancelled'){
        return 'light-danger';
      }else if(status == 'On Hold'){
        return 'light-dark';
      }else if(status == 'Deferred'){
        return 'light-dark';
      }else if(status == 'Submitted'){
        return 'light-dark';
      }else if(status == 'Approved'){
        return 'light-primary';
      }
      return 'light-warning';
    },
    openDetails(node){
      this.selectedNodes = [node];
      this.$bvModal.show('tree-objective-'+this.parent);
      this.$nextTick(() => {
        this.updateProgress();
      });
    },
    updateProgress(){
      // Circle Progress bar Design
      $(".circle_percent").each(function() {
        var $this = $(this),
        $dataV = $this.attr('percent'),
        $dataDeg = $dataV * 3.6,
        $round = $this.find(".round_per");
        $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");	
        $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
        $this.prop('Counter', 0).animate({Counter: $dataV},
        {
          duration: 2000, 
          easing: 'swing', 
          step: function (now) {
                  $this.find(".percent_text").text(Math.ceil(now)+"%");
              }
          });
        if($dataV >= 51){
          $round.css("transform", "rotate(" + 360 + "deg)");
          setTimeout(function(){
            $this.addClass("percent_more");
          },1000);
          setTimeout(function(){
            $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
          },1000);
        } 
      });
    },
    getCurrentYear() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    },
    getCurrentQuarter() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed, so add 1

      // Calculate the quarter based on the current month
      if (currentMonth >= 1 && currentMonth <= 3) {
        return "Q1";
      } else if (currentMonth >= 4 && currentMonth <= 6) {
        return "Q2";
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        return "Q3";
      } else {
        return "Q4";
      }
    },
    getSubNodes(node,k){
      axios
      .post("Objectives/get-okr-tree-subnode/"+node.hashid, {
        year: this.getCurrentYear(),
        quarter: this.getCurrentQuarter(),
      })
      .then((response) => {
        if (response.data.success) {
          node.children = response.data.subnodes;
          this.nodes[k] = node;
          // var tree = new ApexTree(document.getElementById("svg-tree"), this.options);
          // var graph = tree.render(this.nodes);

          
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to fetch Objective Tree",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Objective Tree',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },
    search(text,type,period){
      this.searchType = {text:text,type:type};
      let searchSTR = text;
      if(text.code){
        searchSTR = text.code;
      }
      axios
      .post("Objectives/search-okr" ,{
        year: period.year,
        quarter: period.quarter,
        text:searchSTR,
        type:type
      })
      .then((response) => {
        if (response.data.success) {
          this.selectedNodes = response.data.data;  
          if(this.selectedNodes.length == 0){
            this.$toast({
            component: ToastificationContent,
            props: {
              title: "No Results found",
              icon: "EditIcon",
              variant: "danger",
              text: response.data.message,
            },
          }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }else{
            this.$bvModal.show('tree-objective-'+this.parent);
            this.$nextTick(() => {
              this.updateProgress();
            });  
          }    
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to search in objective tree",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to search in objective tree',
                icon: 'EditIcon',
                variant: 'danger',
                text:  response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },
    listClicked(node){
      node.opened = true;
      var nodeId = '#node-'+node.hashid;
      $('#children-'+this.parent+" li .expand-icon").removeClass("activenode");
      $(nodeId).addClass("open-tree");      
      $(nodeId).siblings('li').removeClass('open-tree');
      $(nodeId).siblings('li').find('ul').hide();
      var children = $(nodeId).find('> ul');
      if (children.is(":visible")) {
        children.hide('fast').removeClass('active');
      }
      else {
        children.show('fast').addClass('active');
        $(nodeId+"-node-arrow").addClass("activenode");
      }  
    },
  },
  computed:{
  }
}
</script>